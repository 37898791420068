<script>
import UserDisplay from "./UserDisplay.vue"

export default {
  name: 'Header',
  components:{
    UserDisplay
  }
}
</script>

<template>
  <header>
    <span id="identity">MyHunt.</span>
    <nav>
      
    </nav>
    <UserDisplay/>
  </header>
</template>

<style lang="scss" scoped>

  @media screen and (max-width: 592px) {
    
    header{
      width: 100%;
    }

  }

  @media screen and (min-width: 592px) {
    
    header{
      width: calc(100% - 4em);
    }

  }



  header {
    margin: auto;
    padding: 1em 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:4em;

    #identity{
      text-align: left;
      font-size: 1.5em;
      font-weight: bold;
    }
  }

</style>