<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'

export default {
  name: 'guestPannel',

  components: {

  },

  data() {
    return {
      guestHuntlist: [],
      onListening: false,
      closed: false,
    }
  },

  computed: {
    ...mapState(['isLogin', 'currentBoard', 'huntList']),

    getGuestUrl() {
      if(this.currentBoard != null || this.currentBoard != undefined) {
        return `https://myhunt.io/?board=${this.currentBoard * 123412}`
      } else {
        return false
      }
    }
  },

  methods:{
    ...mapActions(['updateGuestSlotList', 'saveGuestBoard']),

    copyLink() { 
        let dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        const tooltip = document.getElementById("guestTooltip");
        tooltip.innerHTML = "Copié !";
        dummy.innerText = this.getGuestUrl
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    },

    getParams(){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams
    },

  },

  mounted(){
     if(this.getParams().get("board")){
      const board = parseInt(this.getParams().get("board"))
      axios.get(`${process.env.VUE_APP_API_URL}/board/${(board / 123412).toFixed(0)}`).then(response => {
        if(response.status == 200 && response.data.length > 0){
          this.saveGuestBoard(response.data[0])
        }
      })
      axios.get(`${process.env.VUE_APP_API_URL}/huntlist/${(board / 123412).toFixed(0)}`).then(response => {
        if(response.status == 200 && response.data.length > 0){
          this.guestHuntlist = response.data
          this.updateGuestSlotList(this.guestHuntlist)
          this.onListening = true
        }
      })
    }
  }

    
}

</script>

<template>
  <div class="guest__pannel" v-if="isLogin && huntList.length > 0">
    <div class="tooltip">
      <button v-if="currentBoard != null" @click="copyLink()">
        <span class="tooltiptext"  id="guestTooltip">Copier dans le presse papier</span>
        <span>
          Copier lien du tableau
        </span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>



  .tooltip {
  position: relative;
  display: inline-block;

  button{
    padding: 1em;
    border: 1px solid white;
    background: transparent;
    color: white;
    border-radius: 6px;
    cursor: pointer;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>