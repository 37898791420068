<script>
export default {
  name: "WelcomeSection",
};
</script>

<template>
  <section class="welcome">
    <div class="welcome__slot">
      <img src="../assets/slot.png" alt="" />
    </div>
    <div class="welcome__content">
      <h1>Repoussez les limites !</h1>
      <p>Rejoignez notre communauté discord</p>
      <a class="join-discord" href="https://discord.gg/WDHXYtHCCT" target="_blank">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="discord"
          class="svg-inline--fa fa-discord fa-w-20"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
        >
          <path
            fill="currentColor"
            d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"
          ></path>
        </svg>
        Rejoindre</a
      >
    </div>
  </section>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 592px) {
  .welcome {
    flex-direction: column;
    width: calc(100% - 4em);
    padding: 0 1em;

    .welcome__content {
      h1 {
        margin-top: 1em;
        margin-bottom: 0.5em;
        text-align: center;
      }

      p {
        text-align: center;
      }
    }

    .welcome__slot {
      display: none;
      justify-content: center;
      padding: 0;
      flex: 1;

      img {
        transform: translateY(-10%) scale(1.2);
      }
    }
  }
}

@media screen and (min-width: 592px) {
  .welcome {
    padding: 2em;

    .welcome__slot {
      width: 40%;
      padding-right: 1em;

      img {
        transform: scale(1.7);
      }
    }
  }
}

.welcome {
  background-color: #1c1e2a;
  position: relative;
  display: flex;
  max-width: 500px;
  margin: auto;
  border-radius: 8px;
  transition: all ease 0.2s;
  border: 1px solid #1c1e2a;

  &:hover {
    border: 1px solid #fe4b8c;
  }

  .welcome__content {
    flex: 1;
    text-align: left;

    h1 {
      font-size: 1.5em;
      font-weight: bolder;
    }

    a {
      width: 100%;
    }
  }

  .join-discord {
    padding: 0.7em 1.5em;
    color: #fff;
    background-color: #fe4b8c;
    font-weight: bold;
    border: 1px solid #ff72a6;
    border-radius: 6px;
    margin-bottom: 1em;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all ease 0.2s;
    &:hover {
      transform: scale(1.05);
    }
    svg {
      width: 20px;
      margin-right: 10px;
    }
  }

  .welcome__slot {
    img {
      width: 100%;
      filter: drop-shadow(-5px 5px 4px #0e0f16);
      pointer-events: none;
      text-align: left;
    }
  }
}
</style>
