<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: 'AsideStats',
  data(){
    return{

    }
  },

  computed:{
    ...mapState(['huntList', 'currentBoard', 'currentBoardInfos', 'machinesList', 'guestBoardId', 'statKey']),

    winAmount(){
      let sum = 0;
      this.huntList.forEach((entry) => {
        if(entry.earn != null){
          sum += parseInt(entry.earn);
        }
      })
      return sum;
    },

    amountBeforeWin(){
      let amount = this.currentBoardInfos.start_bet - this.winAmount;
      if (amount > 0){
        return amount;
      } else{
        return 0;
      }
    },

    engagedBonusNumber(){
      let count = 0;
      this.huntList.forEach((entry) => {
        if(entry.slot_id != null && entry.bet != null){
          count += 1
        }
      })
      return count
    },

    bonusNumber() {
      let count = 0;
      this.huntList.forEach((entry) => {
        if(entry.slot_id != null && entry.bet != null && entry.earn != null){
          count += 1
        }
      })
      return `${count}/${this.engagedBonusNumber}`
    },

    breakEven(){
      let betSum = 0
      this.huntList.forEach((entry) => {
        if(entry.slot_id != null){
          betSum += entry.bet
        }
      })
      if(betSum > 0){
        return ((this.currentBoardInfos.start_bet / (betSum / this.huntList.length)) / this.huntList.length).toFixed(2)
      } else {
        return '-'
      }
    },

    bestRatio(){
      let max = 0;
      this.huntList.forEach((entry) => {
        if(entry.slot_id != null && entry.bet != null && entry.earn != null && (entry.earn / entry.bet) > max){
          max = (entry.earn / entry.bet).toFixed(2);
        }
      })
      return max;
    },

    worstRatio(){
      let min = this.bestRatio
      this.huntList.forEach((entry) => {
        if(entry.slot_id != null && entry.bet != null && entry.earn != null && (entry.earn / entry.bet) < min){
          min = (entry.earn / entry.bet).toFixed(2);
        }
      })
      return min;
    },

    middleRatio(){
      let ratios = [];
      let total = 0;
      this.huntList.forEach((entry) => {
        if(entry.slot_id != null && entry.bet != null && entry.earn != null){
          ratios.push(entry.earn / entry.bet)
        }
      })
      ratios.forEach((ratio) => {
        total += ratio
      })
      if (total > 0){
        return (total / (ratios.length)).toFixed(2)
      } else {
        return 0
      }
      
    },

    rentable(){
      if(this.currentBoardInfos.start_bet < this.winAmount){
        return true;
      } else{
        return false;
      }
    },

    bestSlot(){
      let max = 0;
      let bestSlot = null;
      this.huntList.forEach((entry) => {
        if(entry.slot_id != null && entry.bet != null && entry.earn != null && (entry.earn / entry.bet) > max){
          max = (entry.earn / entry.bet).toFixed(2);
          bestSlot = entry.slot_id;
        }
      })
      if (bestSlot != null){
        const machine = this.machinesList.find((machine) => machine.id === bestSlot);
        return machine.name
      } else{
        return '-'
      }
    },

    worstSlot(){
      let min = this.bestRatio;
      let worstSlot = null;
      this.huntList.forEach((entry) => {
        if(entry.slot_id != null && entry.bet != null && entry.earn != null && (entry.earn / entry.bet) < min){
          min = (entry.earn / entry.bet).toFixed(2);
          worstSlot = entry.slot_id;
        }
      })
      if (worstSlot != null){
        const machine = this.machinesList.find((machine) => machine.id === worstSlot);
        return machine.name
      } else{
        return '-'
      }
    },

    
  },
  methods: {
    ...mapActions(['toggleEditBoardModal'])
  }
  
}
</script>

<template>
  <section class="stats" v-if="(currentBoard != null && huntList.length >0) || guestBoardId != null" :key="statKey">
    <ul class="stats__list">
      <li class="stats__card edit_board" v-if="currentBoard != guestBoardId" @click="toggleEditBoardModal()">
        <span>Montant de départ</span>
        <span>{{ currentBoardInfos.start_bet}} {{ currentBoardInfos.devise }} <font-awesome-icon icon="cog" class="cog-icon"/></span>
      </li>
      <li class="stats__card edit_board" v-else>
        <span>Montant de départ</span>
        <span>{{ currentBoardInfos.start_bet}} {{ currentBoardInfos.devise }}</span>
      </li>
      <li class="stats__card">
        <span>Nombre de bonus</span> 
        <span>{{ bonusNumber }}</span>
      </li>
      <li class="stats__card" :class="{'state__green' : rentable}">
        <span>Montant gagné</span>
        <span>{{winAmount}} {{ currentBoardInfos.devise }}</span>
      </li>
      <li class="stats__card" :class="{'state__green' : rentable}">
        <span v-if="!rentable">Montant avant bénéfice</span>
        <span v-else>Bénéfice net</span>
        <span v-if="!rentable">{{ amountBeforeWin }} {{ currentBoardInfos.devise }}</span>
        <span v-else>{{ winAmount - currentBoardInfos.start_bet }} {{ currentBoardInfos.devise }}</span>
      </li>
      <li class="stats__card">
        <span>Break even</span>
        <span>x{{ breakEven }}</span>
      </li>
      <li class="stats__card">
        <span>Meilleur multiplicateur</span>
        <span>x{{ bestRatio }}</span>
      </li>
      <li class="stats__card">
        <span>Multiplicateur moyen</span>
        <span>x{{ middleRatio }}</span>
      </li>
      <li class="stats__card">
        <span>Pire multiplicateur</span>
        <span>x{{ worstRatio }}</span>
      </li>
      <li class="stats__card">
        <span>Meilleur slot</span>
        <span>{{ bestSlot }}</span>
      </li>
      <li class="stats__card">
        <span>Pire slot</span>
        <span>{{ worstSlot }}</span>
      </li>
      <!-- <li class="stats__card">
        <span>Meilleur provider</span>
        <span>Soon</span>
      </li>
      <li class="stats__card">
        <span>Pire provider</span>
        <span>Soon</span>
      </li> -->
    </ul>
  </section>
</template>

<style lang="scss" scoped>

@media screen and (max-width: 1024px){
  .stats{

    .stats__list{

      .stats__card{
        margin-bottom: 0.8em;
      }
    }
  }
}



.stats{
  border-radius: 8px;
  height:100%;

  .edit_board{
    cursor: pointer;

    &:hover{
      .cog-icon{
        opacity: 1;
      }
    }
  }

  .stats__list{
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 8px;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .stats__card{
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
      padding: 1em;
      background-color: #1c1e2a;
      border: 1px solid #2c2e3f;
      border-radius: 8px;
      transition: all ease .8s;
      align-items: center;
    }

    .stats__card span:first-child{
      text-align: left;
    }

    .state__green{
      border: 1px solid #45dfa4;
    }
  }
}

.cog-icon{
  opacity: 0.5;
  margin-left: 5px;
  transition: all ease .3s;
}

.wide {
  .stats__list{
    justify-content: flex-start;

    li{
      margin-bottom: 1em;
    }
  }
}

</style>