<script>
import { mapState, mapActions } from "vuex";
import HuntList from "@/components/HuntList.vue";
import axios from "axios";

export default {
  name: "MainHunt",
  components: {
    HuntList,
  },
  data() {
    return {
      key: 0,
    };
  },
  computed: {
    ...mapState([
      "currentBoard",
      "huntList",
      "onAction",
      "slotList",
      "machinesList",
      "huntListKey",
      "guestBoard",
      "guestBoardId",
      "returnToSelectList",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchHuntList",
      "newSlotHuntList",
      "multiDeleteSlots",
      "toggleGeneratorModal",
      "resetOnAction",
    ]),

    insertNewSlot() {
      this.newSlotHuntList();
    },

    forceRerender() {
      this.key += 1;
    },

    handleMultiDeleteSlots() {
      // out of the slotList
      // Return to select list
      this.huntList.forEach((hunt) => {
        if (this.onAction.includes(hunt.id)) {
          if (this.slotList.includes(hunt.slot_id)) {
            const index = this.slotList.indexOf(hunt.slot_id);
            this.slotList.splice(index, 1);
            // console.log("slot ", hunt.slot_id, " away from slotList");
            // console.log("actual slotList : ", this.slotList);
          }
          if (hunt.slot_id != null && !this.returnToSelectList.includes(hunt.slot_id))
            this.returnToSelectList.push(hunt.slot_id);
        }
      });

      // console.log("actual return to selectList", this.returnToSelectList);

      this.multiDeleteSlots();
    },

    // Beta - Disabled
    rerollSlots() {
      // For all slots selected
      this.onAction.forEach(async (slot) => {
        let randomIndex = null;
        // Check if the machine is already in huntList
        while (
          randomIndex === null ||
          this.slotList.includes(this.machinesList[randomIndex].id)
        ) {
          randomIndex = Math.floor(Math.random() * this.machinesList.length);
        }
        try {
          axios.put(`${process.env.VUE_APP_API_URL}/huntlist/${slot}`, {
            slot_id: this.machinesList[randomIndex]._id,
          });
          // console.log(this.machinesList[randomIndex]._id)
        } catch (e) {
          this.errors.push(e);
        }
      });
      this.fetchHuntList();
      this.resetOnAction();
    },
  },
};
</script>

<template>
  <div class="hunt">
    <div class="table">
      <div class="table__name">
        <span>Nom du slot</span>
      </div>
      <div class="table__bet">
        <span>Pari</span>
      </div>
      <div class="table__earn">
        <span>Gagné</span>
      </div>
      <div class="table__ratio">
        <span>Multiplicateur</span>
      </div>
      <div class="table__actions">
        <span>Actions</span>
      </div>
    </div>
    <div class="table m-table">
      <div class="table__name">
        <span>Slot</span>
      </div>
      <div class="table__bet">
        <span>P</span>
      </div>
      <div class="table__earn">
        <span>G</span>
      </div>
      <div class="table__ratio">
        <span>M</span>
      </div>
      <div class="table__actions">
        <span>A</span>
      </div>
    </div>

    <div class="slots" v-if="currentBoard != null || guestBoardId != null">
      <HuntList :key="key" />
      <div
        class="slots__controls"
        v-if="currentBoard != guestBoardId"
        :class="{ 'full-height': huntList.length < 1 }"
      >
        <div class="slots__message" v-if="huntList.length < 1">
          <h3>Ajoutes tes premieres slots !</h3>
          <span>Ajoutes les une par une, ou génères les en 1 clic (soon) !</span>
        </div>
        <div class="slots__buttons" v-if="onAction.length === 0">
          <button @click="insertNewSlot()" class="btn__new__slot">
            <font-awesome-icon icon="plus" />
            <span>Ajouter une slot</span>
            <!-- <span v-show="btnAddLoad">
              <img width="20" height="20" src="../assets/loader_white.gif">
            </span> -->
          </button>
          <button @click="toggleGeneratorModal()">
            <span>Générer des slots</span>
          </button>
        </div>
        <div class="slots__buttons" v-else>
          <button @click="this.handleMultiDeleteSlots()" class="btn__new__slot">
            <font-awesome-icon icon="times" />
            <span>Supprimer les slots</span>
            <!-- <span v-show="btnDelLoad">
              <img width="20" height="20" src="../assets/loader_white.gif">
            </span> -->
          </button>
          <button disabled>
            <span>Reroll les slots (soon)</span>
          </button>
          <!-- <button @click="forceRerender()">key</button> -->
        </div>
      </div>
    </div>

    <div class="hunt__message" v-else>
      <span>Veuillez sélectionner une board !</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 592px) {
  .hunt {
    .table {
      padding: 1em;
      display: none;
    }

    .table.m-table {
      display: grid;
    }

    .slots {
      padding: 1em 1px;

      .slots__controls {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 592px) {
  .hunt {
    .table {
      padding: 2em;
    }

    .slots {
      padding: 1em 2em;
      padding-bottom: 5em;

      .slots__controls {
        width: 50%;
      }
    }

    .table.m-table {
      display: none;
    }
  }
}

.hunt {
  position: relative;
  height: 700px;
  border-radius: 8px;
  position: relative;
  overflow-y: scroll;

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  & {
    scrollbar-width: auto;
    scrollbar-color: #1c1e2a #ffffff;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1c1e2a;
    border-radius: 10px;
  }

  .hunt__message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      font-weight: bold;
      font-size: 1.4em;
    }
  }

  .slots {
    .slots__message {
      h3 {
        font-weight: bold;
        font-size: 1.4em;
        margin: 1em;
      }
    }

    .slots__controls {
      margin: auto;
      position: sticky;
      bottom: 1em;

      .slots__buttons {
        margin-top: 1.5em;
        display: inline-flex;
        width: 100%;

        button {
          width: 50%;
          margin: 0.5em;
          padding: 1em;
          font-weight: bold;
          border: none;
          border-radius: 6px;
          margin-bottom: 1em;
          cursor: pointer;
          transition: all ease 0.2s;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }

        button:hover {
          width: 60%;
        }

        .btn__new__slot {
          color: #fff;
          background-color: #fe4b8c;
          border: 1px solid #ff72a6;

          span {
            margin-left: 1em;
          }
        }
      }
    }

    .full-height {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.table {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 0.7fr;
  border-radius: 8px;
  align-items: center;
  background-color: #1c1e2a;
  border: 1px solid #2c2e3f;
  // -webkit-box-shadow: 0px 15px 25px 9px rgba(79,85,201,0.27);
  // box-shadow: 0px 15px 25px 9px rgba(79,85,201,0.27);
  position: sticky;
  top: 0;
  z-index: 198;
  & div:last-child {
    border: none;
  }
  & div {
    flex: 1;
    text-align: center;
    border-right: 1px solid lightgray;
  }
  & .table__name {
    text-align: left;
    min-width: 50%;
  }
  span {
    font-weight: bold;
  }
}
</style>
