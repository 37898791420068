<script>
import { mapState, mapActions } from 'vuex'
import CardSlot from '@/components/CardSlot.vue'
import GuestCardSlot from '@/components/GuestCardSlot.vue'

export default {
  name: 'HuntList',
  components:{
    CardSlot,
    GuestCardSlot,
  },
  data() {
    return {
      key: 0, 
    }
  },
  
  computed:{
    ...mapState(['huntList', 'currentBoard', 'slotList', 'guestBoard', 'guestHuntList', 'guestBoardId', 'guestKey'])
  },
  method:{
    ...mapActions(['fetchHuntList'])
  },


}
</script>


<template>
<div v-if="currentBoard != null">
  <div class="huntlist" v-if="guestBoardId != currentBoard">
    <CardSlot
      v-for="item in huntList"
      :key="item.id"
      :id="item.id"
      :slot_id="item.slot_id"
      :bet="item.bet"
      :earn="item.earn"
    />
  </div>
  <div class="huntlist" v-else :key="guestKey">
    <GuestCardSlot
      v-for="item in guestHuntList"
      :key="item.id"
      :id="item.id"
      :slot_id="item.slot_id"
      :bet="item.bet"
      :earn="item.earn"
    />
  </div>
</div>

</template>

<style lang="scss" scoped>


</style>