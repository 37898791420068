<script>
import { mapState } from 'vuex'
import MsgCard from '@/components/MsgCard.vue'

export default {
  name: "MsgDisplay",

  components:{
    MsgCard,
  },

  computed:{
    ...mapState(['errors'])
  }
}
</script>

<template>
<section class="msg">
  <MsgCard 
    v-for="item in errors"
    :key="item.title"
    :title="item.title"
    :description="item.description"
  />
</section>
  
</template>

<style lang="scss" scoped>

  section.msg{
    position: fixed;
    top: 5em;
    left: 4em;
    z-index: 999;

  }

</style>