<script>
export default {
  name: 'Footer'
}
</script>

<template>
  <footer>
    
    <div class="footer__content">
      <div>
        <h4>Jeu responsable</h4>
        <p>La plupart des gens jouent pour le plaisir. Cependant, certaines personnes considèrent le jeu comme un moyen de gagner de l'argent,
          de dépenser plus que ce qu'elles peuvent se permettre ou d'utiliser le jeu pour se distraire des problèmes quotidiens.
           Suivre ces conseils peut vous aider à jouer en toute sécurité.</p>
      </div>
      <div>
        <h4>Informations</h4>
        <p>
          MyHunt n'est pas responsable des informations incorrectes sur les bonus, offres ou promotions répertoriés sur le site. MyHunt recommande que le joueur examine toutes les conditions générales de tous les bonus répertoriés sur le site Web du Casino.</p>
      </div>
    </div>
    <div class="copyright">
      <span>MyHunt © 2021</span>
    </div>
  </footer>
</template>


<style lang="scss" scoped>

@media screen and (max-width: 592px){
  footer{

    .footer__content{
      flex-direction: column;
      padding: 2em;
    }
  }
}

@media screen and (min-width: 592px){
  footer{

    .footer__content{
      padding: 6em 0;
    }
  }
}

  footer{
    width: 100%;
    background-color: #1C1E2A;
    margin-top: 4em;

  .footer__content{
    max-width: 1600px;
    margin: auto;
    display: flex;
    justify-content:space-around;
    text-align: left;

    div{
      max-width: 600px;
    }

    h4{
      font-size: 1.4em;
      font-weight: bold;
      color: #FE4B8B;
    }

    h4::after{
      display: block;
      content: '';
      margin-top: 1em;
      width: 30px;
      height: 1px;
      background-color: #FE4B8B;
    }
  }

  .copyright {
    padding: 1em 0;
    font-size: 0.7em;
  }
  }

</style>