<template>
  <section>
    <Header />
    <MsgDisplay />
    <NewBoardModal v-show="boardModal" />
    <GenerateSlotModal v-show="generatorModal" />
    <EditBoardModal v-if="editBoardModal" />
    <WelcomeSection />
    <LoginModal />
    <BonusHuntInterface />
    <div class="stake__manual">
      <div class="inner">
        <h2 class="major">
          Casino Stake<br />
          comment ça fonctionne ?
        </h2>
        <p>
          <a href="https://stake.bet/?c=Doc9" target="_blank">Stake</a> est un casino qui
          fonctionne avec les crypto-monnaies, je vous explique ici simplement comment
          avoir les bonus disponibles. Je vous rassure,
          <strong>c'est très simple</strong> et ce sont les meilleurs bonus disponibles
          sur le marché.
        </p>
        <h3 class="major">S'inscrire et profiter des 30€ offerts</h3>
        <p>
          Pour vous inscrire sur
          <a href="https://stake.bet/?c=Doc9" target="_blank">Stake</a> il vous suffit de
          <strong
            ><a href="https://stake.bet/?c=Doc9" target="_blank">cliquer ici</a></strong
          >.<br />
          En vous inscrivant via ce lien, vous bénéficiez de
          <strong>30€ offerts</strong> après votre premier <strong>dépôt de 20€</strong>.
          Comment ça marche? Inscrivez vous sur Stake, «<i
            >Si besoin d'un VPN vous avez Avira VPN, Hide.me, Windscribe, RUSVpn</i
          >» déposez 20€ dans n'importe quelle crypto «<i
            >Je vous conseille le Litecoin, c'est le moins cher en termes de frais</i
          >» et découvrez le site, en jouant, on ne vous demande pas de perdre vos 20€
          mais de jouer pour découvrir le site. Une fois que vous avez déposé vos 20€,
          envoyez un message privé sur Télégram au compte
          <a href="https://t.me/Stake_Bonus_Bot" target="_blank"
            >Doc9 en suivant ce lien</a
          ><br />
        </p>
        <br />
        <p>
          Vous retrouverez tous les bonus en détail sur :
          <a href="https://www.youtube.com/watch?v=CrBLkTzFCW4" target="_blank"
            >cette vidéo YouTube</a
          >
          !
        </p>
        <h3 class="major">Profiter du bonus 100% ou 200% sans conditions</h3>
        <p>
          Ce n'est pas tout.. Tu es un joueur avec de plus gros moyens ? Alors
          <a href="https://stake.bet/?c=Doc9" target="_blank">Stake</a> est la meilleure
          option pour toi!<br />
          Tu peux déposer entre 200€ et 5000€ pour profiter de ce bonus, tu as le
          <strong>choix entre un 100% et un 200% sur ton dépôt</strong>, en gros si tu
          déposes 200€ et que tu prends le 200% tu vas démarrer ta session avec 600€, 400€
          que le casino va te fournir via ton bonus. Tu n'as
          <strong>AUCUNE CONDITION DE MISES</strong>, tu peux jouer comme tu veux, faire
          du blackjack, jouer avec le bet que tu veux, pas de limites.<br />
          La seule condition qu'il y a pour pouvoir retirer l'argent est un wager x30 pour
          le 100% et x35 pour le 200%, en gros tu dois jouer si on reprend l'exemple au
          dessus 600€ x 35 = 21.000€. Imaginons tu as joué les 21.000€ et il te reste
          10.000€ sur Stake, tu pourras retirer!<br />
          Pour pouvoir profiter de ce bonus, il est essentiel de contacter le support sur
          Télégram pour savoir si tu es éligible. Pour contacter le support il te suffit
          de
          <a href="https://t.me/Stake_Bonus_Bot" target="_blank">suivre ce lien</a><br />
        </p>
        <h3 class="major">Retirer vos gains</h3>
        <p>
          Il vous faudra un wallet, je vous conseille
          <a href="https://www.binance.com/en/register?ref=KDBXDWZB" target="_blank"
            >Binance</a
          >, c'est le plus connu et il est très simple. Pour déposer envoyez depuis
          <a href="https://www.binance.com/en/register?ref=KDBXDWZB" target="_blank"
            >Binance</a
          >
          sur le casino, pour éviter tous types de frais.
        </p>
        <h3 class="major">Avertissement</h3>
        <p>
          Ne vous mettez pas dans la merde, ne jouez que l'argent que vous pouvez et
          surtout, prenez ça pour le plaisir, vous n'allez pas gagner votre vie comme ça.
          De plus le casino est interdit aux mineurs! Bonne chance à vous!<br />
          Si vous avez besoin d'aide : <br />
          08 00 23 13 13 <br />
          <a href="https://joueurs-info-service.fr/">Joueurs info service</a>
          <br /><br /><i
            >Note : Ce site n'est pas un site affilié, le texte est fait à des fins
            éducatives uniquement.
          </i>
        </p>
        <br />
      </div>
    </div>
    <Footer />
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import LoginModal from "@/components/LoginModal.vue";
import BonusHuntInterface from "@/components/BonusHuntInterface.vue";
import { mapActions, mapState } from "vuex";
import NewBoardModal from "@/components/modals/NewBoardModal.vue";
import GenerateSlotModal from "@/components/modals/GenerateSlotModal.vue";
import Footer from "@/components/Footer.vue";
import WelcomeSection from "@/components/WelcomeSection.vue";
import MsgDisplay from "@/components/MsgDisplay.vue";
import EditBoardModal from "@/components/modals/EditBoardModal.vue";

export default {
  name: "App",
  components: {
    Header,
    LoginModal,
    BonusHuntInterface,
    NewBoardModal,
    GenerateSlotModal,
    Footer,
    WelcomeSection,
    MsgDisplay,
    EditBoardModal,
  },
  computed: {
    ...mapState([
      "user",
      "boardModal",
      "generatorModal",
      "errors",
      "currentBoard",
      "editBoardModal",
      "currentBoardInfos",
      "guestBoardId",
    ]),
  },
  methods: {
    ...mapActions(["setUser", "fetchMachinesList", "fetchProviders", "addError"]),
  },

  mounted() {
    // console.log('App mounted')
    // If a user is present in local storage, load his profile
    if (localStorage.getItem("user")) {
      // console.log('Retrieving session')
      try {
        const oldUser = JSON.parse(localStorage.getItem("user"));
        // Load profile only if connexion was less than 10 hour ago ELSE delete the storage
        if (Date.now() - parseInt(oldUser.connected_at) < 36000000) {
          this.setUser(oldUser);
        } else {
          // console.log('Clean session')
          localStorage.removeItem("user");
        }
      } catch (e) {
        // If error, clean local storage
        // console.log(e)
        localStorage.removeItem("user");
      }
    }
    this.fetchProviders();
    this.fetchMachinesList();
  },
};
</script>

<style lang="scss">
$primary: #252849;
$tprimary: #f6f6f6;

* {
  box-sizing: border-box;
}

#app {
  max-width: 100vw;
  position: relative;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $tprimary;

  .stake__manual {
    margin-top: 4em;

    h2 {
      text-align: center;
    }

    p,
    h3 {
      text-align: left;
    }

    .inner {
      max-width: 500px;
      width: 100%;
      margin: auto;
      background-color: #1c1e2a;
      padding: 1em 2em;
      border-radius: 8px;

      .major {
        font-weight: bold;
      }

      a {
        color: #ff4b8c;
      }
    }
  }
}
</style>
