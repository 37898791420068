<script>
import { mapState, mapActions } from "vuex";
import Multiselect from "@vueform/multiselect";
import axios from "axios";

export default {
  name: "cardslot",
  components: {
    Multiselect,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    slot_id: {
      type: Number,
      default: null,
    },
    bet: {
      type: Number,
      default: null,
    },
    earn: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      slotNameInput: false,
      slotBetInput: false,
      slotEarnInput: false,
      slotName: null,
      slotBet: null,
      slotEarn: null,
      action: false,
      updateBody: {
        slot_id: null,
        bet: null,
        earn: null,
      },
      errors: [],
    };
  },
  computed: {
    ...mapState([
      "slotList",
      "machinesList",
      "currentBoard",
      "selectList",
      "currentDevise",
      "onAction",
      "huntList",
      "user",
      "returnToSelectList",
    ]),

    machineName() {
      const machine = this.machinesList.find(
        (machine) => machine.id === this.updateBody.slot_id
      );
      return machine.name;
    },

    multiplicator() {
      if (this.updateBody.bet != null && this.updateBody.earn != null) {
        return (this.updateBody.earn / this.updateBody.bet).toFixed(0);
      } else {
        return "-";
      }
    },

    solved() {
      if (
        this.updateBody.slot_id != null &&
        this.updateBody.bet != null &&
        this.updateBody.earn != null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchHuntList",
      "pushSlotToQueue",
      "removeSlotFromQueue",
      "actualizeSelectList",
      "updateLocalHuntlistEntry",
      "actualizeSlotList",
      "addError",
    ]),

    toggleNameInput() {
      this.slotNameInput = !this.slotNameInput;

      if (this.slotNameInput === true) {
        this.$nextTick(() => {
          let multiselect = this.$refs.name;
          multiselect.input.focus();
        });
      }
    },

    closeName() {
      this.$nextTick(() => {
        this.slotNameInput = false;
        this.actualizeSlotList();
      });
    },

    toggleBetInput() {
      this.slotBetInput = !this.slotBetInput;

      if (this.slotBetInput === true) {
        this.$nextTick(() => {
          let input = this.$refs.bet;
          input.focus();
        });
      }
    },
    toggleEarnInput() {
      this.slotEarnInput = !this.slotEarnInput;

      if (this.slotEarnInput === true) {
        this.$nextTick(() => {
          let input = this.$refs.earn;
          input.focus();
        });
      }
    },

    async updateHuntlistDatabase() {
      {
        this.updateLocalHuntlistEntry({
          id: this.id,
          slot_id: this.updateBody.slot_id,
          bet: this.updateBody.bet,
          earn: this.updateBody.earn,
        });
        try {
          await axios
            .put(`${process.env.VUE_APP_API_URL}/huntlist/${this.id}`, {
              board_id: this.currentBoard,
              slot_id: this.updateBody.slot_id,
              bet: this.updateBody.bet,
              earn: this.updateBody.earn,
              google_id: this.user.google_id,
              token: this.user.token,
            })
            .then((response) => {
              if (response.status === 200) {
                this.actualizeSlotList();
              }
            });
        } catch (e) {
          if (e.message === "Request failed with status code 300") {
            this.addError({
              title: "Jeton de sécurité invalide",
              description: "Pour des raisons de sécurité, veuillez vous reconnecter.",
            });
          }
        }
      }
    },

    sendName() {
      if (this.updateBody.slot_id != null) {
        if (!this.returnToSelectList.includes(this.updateBody.slot_id))
          this.returnToSelectList.push(this.updateBody.slot_id);
        const index = this.slotList.indexOf(this.updateBody.slot_id);
        this.slotList.splice(index, 1);
      }
      this.key += 1;
      this.$nextTick(() => {
        this.updateHuntlistDatabase();
        // console.log(this.updateBody.slot_id);
      });
    },

    actualizeActionQueue() {
      if (this.action) {
        if (!this.onAction.includes(this.id)) {
          this.pushSlotToQueue(this.id);
        }
      } else {
        const slot = (slot) => slot === this.id;
        this.removeSlotFromQueue(slot);
      }
    },

    verifyEarn() {
      if (this.slotEarn != null) {
        if (this.slotEarn.toString().length === 0) {
          this.slotEarn = null;
          this.updateBody.earn = null;
        }
      }
    },

    verifyBet() {
      if (this.slotBet != null) {
        if (this.slotBet.toString().length === 0) {
          this.slotBet = null;
          this.updateBody.bet = null;
        }
      }
    },
  },
  beforeMount() {
    this.updateBody.slot_id = this.slot_id;
    this.updateBody.bet = this.bet;
    this.slotBet = this.bet;
    this.updateBody.earn = this.earn;

    if (this.onAction.includes(this.id)) {
      this.action = true;
    }
  },
};
</script>

<template>
  <div class="card-slot" :class="{ solved: solved }" v-if="currentBoard != null">
    <div class="slot-name">
      <span v-show="slotNameInput === false" @click="toggleNameInput()">{{
        this.updateBody.slot_id === null ? "Cliquez pour modifier" : machineName
      }}</span>
      <Multiselect
        ref="name"
        v-show="slotNameInput === true"
        v-model="this.updateBody.slot_id"
        :limit="20"
        :options="selectList"
        :searchable="true"
        :placeholder="'Choisissez une slot'"
        class="custom__select"
        @change="toggleNameInput(), sendName()"
        @close="closeName()"
      />
    </div>
    <div>
      <span v-show="this.slotBetInput === false" @click="toggleBetInput">{{
        this.updateBody.bet === null ? "-" : `${this.updateBody.bet} ${currentDevise} `
      }}</span>
      <input
        v-show="this.slotBetInput === true"
        ref="bet"
        type="number"
        min="0"
        v-model="this.slotBet"
        class="multiselect nb_input"
        @focusout="this.slotBetInput = false"
        @keyup.enter="
          verifyBet(),
            (this.updateBody.bet = this.slotBet),
            toggleBetInput(),
            updateHuntlistDatabase()
        "
      />
    </div>
    <div>
      <span v-show="this.slotEarnInput === false" @click="toggleEarnInput">{{
        this.updateBody.earn === null ? "-" : `${this.updateBody.earn} ${currentDevise} `
      }}</span>
      <input
        v-show="this.slotEarnInput === true"
        ref="earn"
        type="number"
        min="0"
        v-model="this.slotEarn"
        class="multiselect nb_input"
        @focusout="this.slotEarnInput = false"
        @keyup.enter="
          verifyEarn(),
            (this.updateBody.earn = this.slotEarn),
            toggleEarnInput(),
            updateHuntlistDatabase()
        "
      />
    </div>
    <div>
      <span v-if="multiplicator === '-'">-</span>
      <span v-else>x{{ multiplicator }}</span>
    </div>
    <div class="card__action">
      <input
        @change="actualizeActionQueue()"
        type="checkbox"
        id="switch"
        v-model="this.action"
      />
    </div>
  </div>
</template>

<style lang="scss">
@import "@vueform/multiselect/themes/default";

.card-slot {
  border-bottom: 1px solid #363f52;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 0.7fr;
  align-items: center;
  padding: 1.2em 1em;
  transition: all ease 0.2s;

  & .slot-name {
    text-align: left;
    min-width: 50%;
  }

  & span {
    padding: 0 1em;
    cursor: pointer;
  }

  & .nb_input {
    text-align: center;
  }

  & input {
    width: 80%;
    min-width: 50px;
  }
}

#switch {
  height: 17px;
}

.solved {
  border-left: 1px solid #fe4b8b !important;
}

.custom__select {
  color: #222;
  background: white;
}
</style>
