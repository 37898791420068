<script>
import { mapState } from 'vuex';

export default {
  name: 'guestcardslot',
  props:{
    id: {
      type: Number,
      default: null,
    },
    slot_id: {
      type: Number,
      default: null,
    },
    bet: {
      type: Number,
      default: null,
    },
    earn: {
      type: Number,
      default: null,
    },
  },
  data(){
    return{
      slotNameInput: false,
      slotBetInput: false,
      slotEarnInput: false,
      slotName: null,
      slotBet: null,
      slotEarn: null,
      action: false,
      updateBody: {
        slot_id: null,
        bet: null,
        earn: null
      },
      errors: [],
    }
  },
  computed:{
    ...mapState(['guestSlotList', 'machinesList', 'guestDevise']),


    machineName(){
      const machine = this.machinesList.find((machine) => machine.id === this.updateBody.slot_id)
      return machine.name
    },

    multiplicator() {
      if(this.updateBody.bet != null && this.updateBody.earn != null){
        return (this.updateBody.earn / this.updateBody.bet).toFixed(0)
      } else {
        return '-'
      }
    },

    solved(){
      if(this.slot_id != null && this.bet != null && this.earn != null){
        return true
      } else{
        return false
      }
    }
  },
  methods: {
    
  },
  beforeMount(){
    this.updateBody.slot_id = this.slot_id;
    this.updateBody.bet = this.bet;
    this.slotBet = this.bet;
    this.updateBody.earn = this.earn;

  },


  

}
</script>

<template>
  <div class="card-slot" :class="{'solved' : solved}">
    <div class="slot-name">
      
        <span>{{ this.updateBody.slot_id === null ? 'A définir' : machineName  }}</span>
      </div>
      <div>
        <span>{{ this.updateBody.bet === null ? '-' : `${this.updateBody.bet}${guestDevise}`}}</span>
      </div>
      <div>
        <span>{{ this.updateBody.earn === null ? '-' : `${this.updateBody.earn}${guestDevise}`}}</span>
      </div>
      <div>
        <span v-if="multiplicator === '-'">-</span>
        <span v-else>x{{ multiplicator }}</span>
      </div>
  </div>
</template>

<style lang="scss">
  @import '@vueform/multiselect/themes/default';
  
  .card-slot{
    border-bottom: 1px solid #363f52;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 0.7fr;
    align-items: center;
    padding: 1.2em 1em;
    transition: all ease .2s;

    & .slot-name{
      text-align: left;
      min-width: 50%;
    }

    & span{
      padding: 0 1em;
      cursor: pointer;
    }

    & .nb_input {
      text-align: center;
    }

    & input{
      width: 80%;
      min-width: 50px;
    }
  }

  #switch {
    height: 17px;
  }

  .solved{
    border-left: 1px solid #FE4B8B !important;
  }

  .custom__select{
    color: #222;
    background: white;
  }

</style>