<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'LoginModal',
  data() {
    return {
      isInit: false,
      token : null,
    }
  },
  computed:{
    ...mapState(['isLogin'])
  },
  methods: {
    ...mapActions(['setUser', 'addError']),

    async handleClickSignIn(){
      try {
        const googleUser = await this.$gAuth.signIn()
        const authResponse = googleUser.getAuthResponse()
        if (authResponse) {
          const connectDate = Date.now()
          const user = {
            google_id: googleUser.getId(),
            username: googleUser.getBasicProfile().getName(),
            email: googleUser.getBasicProfile().getEmail(),
            picture_url: googleUser.getBasicProfile().getImageUrl(),
            connected_at: connectDate,
            token: authResponse.id_token,
          };
          this.setUser(user)
        }
        // console.log(this.token)
      } catch (error) {
        // console.error(error);
        this.addError(error)
      }
    },
  },
}
</script>

<template>
<div class="container" v-if="!isLogin">
  <div class="modal" v-if="!isLogin">
    <h2 class="modal__title">Connectez-vous !</h2>
    <p class="modal__content">L'application sauvegarde tous vos Bonus Hunt.<br> Vous connecter permet de les retrouver en toutes circonstances.</p>
    <button @click="handleClickSignIn()" class="g-button">
      <span><img src="../assets/google-brands.svg" alt=""> Se connecter avec Google</span>
    </button>
  </div>
</div>
</template>

<style lang="scss" scoped>

  .container{
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4em 0;
  }

  .modal{
    background-color: #1C1E2A;
    padding: 2em;
    border-radius: 8px;

    .modal__title{
      font-size: 2em;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .g-button{
      padding: 1em;
      background-color: #E8EDDF;
      cursor: pointer;
      border: none;
      border-radius: 4px;
      margin-top: 2em;

      span{
        display: flex;
        align-items: center;
        width: 100%;

        img{
          width: 20px;
          margin-right: 1em;
        }
      }
    }
  }

</style>