<script>
import { mapState, mapActions } from 'vuex';
import Multiselect from '@vueform/multiselect'

export default {
  name: "NewBoardModal",
  components:{
    Multiselect,
  },

  data() {
    return {
      postBody: {
        name: '',
        devise: '',
        start_bet: '',
      },
      optionsDevise: ['€', '$', '£'],
      errors: [],
    }
  },

  computed:{

    ...mapState(['boards', 'boardModal']),

    validateFields: function() {
      if(this.postBody.name != '' && this.postBody.devise != '' && this.postBody.start_bet != '') {
        if(this.postBody.name.length > 2){
          return true;
        }
        else {
          return false;
        }
      } else{
        return false;
      }
    },
  },

  methods: {

    ...mapActions(['postBoard', 'toggleBoardModal']),

    resetFields: function() {
      this.postBody.name = ''
      this.postBody.devise = ''
      this.postBody.start_bet = ''
    }
  }
}
</script>

<template>
  <div class="modal" @keyup.esc="toggleBoardModal()">
    <h2 class="modal__title">Nouveau tableau ? 🎲</h2>
    <p class="modal__desc">Choisissez un nom, une devise et une mise de départ.</p>
    <div class="modal__form-group">
      <div class="form-row">
        <input v-model="postBody.name" class="form-row__input" type="text" placeholder="Nom"/>
      </div>
      <div class="form-row">
        <Multiselect
          v-model="this.postBody.devise"
          :options="optionsDevise"
          :placeholder="'Devise'"
          class="custom__select"
        />
        <input v-model="postBody.start_bet" class="form-row__input" type="number" min="0" placeholder="Mise totale"/>
      </div>
      <div class="form-row">
        <button @click="postBoard(this.postBody), toggleBoardModal(), resetFields()" class="modal__button" :disabled="!validateFields" :class="{'button__disabled' : !validateFields}">Créer</button>
      </div>
    </div>
    <div class="modal__cross" @click="toggleBoardModal()"><font-awesome-icon icon="times" /></div>
  </div>
</template>

<style lang="scss" scoped>

  @media screen and (max-width: 592px){
    div.modal{
      width: calc(100% - 2em);
      margin: auto;

      .modal__title, .modal__desc{
        text-align: center;
      }
    }
  }

  .modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 199;
    background-color: #1C1E2A;
    padding: 2em;
    border-radius: 10px;
    -webkit-box-shadow: 9px 10px 45px 4px rgba(0,0,0,0.32);
    -moz-box-shadow: 9px 10px 45px 4px rgba(0,0,0,0.32);
    box-shadow: 9px 10px 45px 4px rgba(0,0,0,0.32);
    border: solid 1px #FE4B8B;;

    .modal__title{
      margin-bottom: 0.5em;
      text-align: left;
    }

    .modal__desc{
      text-align: left;
      margin-top: 0;
      margin-bottom: 2em;
    }

    .modal__button{
      margin-top: 1em;
      width: 100%;
      padding: 0.9em 0;
      color: #f2f2f2;
      background-color: #FE4B8B;
      border: none;
      border-radius: 6px;
      font-size: 1.1em;
      font-weight: bold;
      cursor: pointer;
    }

    .button__disabled {
      cursor: default;
      background-color: rgb(119, 119, 119);
    }

    .form-row {
      display: flex;
      margin: 16px 0px;
      gap:16px;
      flex-wrap: wrap;
    }

    .form-row__input {
      padding: 11px;
      border-radius: 4px;
      background: #fff;
      font-weight: 500;
      font-size: 16px;
      flex: 1;
      border: 1px solid #2c2e3f;
      min-width: 100px;
      font-weight: 100;
      color: #252C3A;
    }

    .form-row__input::placeholder{
      color:#9ca3af !important;
    }

    .custom__select{
      border: 1px solid #d1d5db;

      .multiselect-option.is-pointed{
        background-color: #FE4B8B;
        color: #fff;
      }
    }
    
    .form-row__input::placeholder {
      color: #252C3A;
    }

    .modal__cross{
      position: absolute;
      top: 0.7em;
      right: 1em;
      font-size: 2em;
      color: #252C3A;
      cursor: pointer;
      transition: all ease .2s;
    }

    .modal__cross:hover{
      color: #fff;
    }
  }

</style>