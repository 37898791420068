<script>
import { mapState } from 'vuex'
import AsideBoards from '@/components/AsideBoards.vue'
import MainHunt from '@/components/MainHunt.vue'
import AsideStats from '@/components/AsideStats.vue'
import GuestPannel from '@/components/GuestPannel.vue'

export default {
  name: 'BonusHuntInterface',
  data() {
    return {
      isWide: false
    }
  },
  components:{
    AsideBoards,
    MainHunt,
    AsideStats,
    GuestPannel
  },
  computed:{
    ...mapState(['isLogin', 'boardModal', 'generatorModal', 'boardLoader'])
  },
  methods: {
    toggleIsWide(){
      this.isWide = !this.isWide
    }
  }
}
</script>

<template>
  <div class="interface" v-if="isLogin" :class="{'blured' : boardModal || generatorModal, 'wide' : isWide}">
    <div class="interface__controls">
      <button class="wide-btn" @click="toggleIsWide()">
         <font-awesome-icon icon="expand" v-if="!isWide"/>
         <font-awesome-icon icon="compress" v-else/>
      </button>
      <GuestPannel />
    </div>
    <div class="interface__grid">
      <div class="interface__aside">
        <AsideBoards />
      </div>
      <div class="interface__main">
        <MainHunt />
      </div>
      <div class="interface__aside stats">
        <AsideStats />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  .interface__controls{
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
  }

  .blured{
    filter: blur(10px);
    pointer-events: none;
  }


  @media screen and (max-width: 1300px) {

    .interface {
      width: calc(100vw - 2em);
      margin: 2em auto;
      border-radius: 16px;
      height: auto;
    }

    .wide-btn{
      display: none !important;
    }

    .interface__grid{
      display: block;
      column-gap: 16px;
      color: #f6f6f6;

      .interface__aside{
        margin-bottom: 2em;
      }

      .interface__main{
        margin-bottom: 2em;
      }
    }
  }

  @media screen and (min-width: 1300px) {

    .interface {
      width: calc(100vw - 4em);
      margin: 2em auto;
      border-radius: 16px;
      height: auto;
    }

    .interface__grid{
      display: grid;
      column-gap: 16px;
      color: #f6f6f6;
      max-height: 700px;
    }
  }


  .wide-btn{
    padding: 0.5em;
    display: block;
    border: none;
    background: transparent;
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
    font-size: 1.2em;

    &:hover{
      background-color: #1C1E2A;
    }
  }

  .interface {
    margin: 6em auto;
    border-radius: 16px;
    max-width: 1600px;
    background-color: #262c3a;
    padding: 1em;
    transition: all ease 0.2s;
    // background: -webkit-linear-gradient(to right, #8f94fb, #4e54c8);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to right, #8f94fb, #4e54c8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  &.wide{
    transition: all ease 0.2s;
    max-width: none;

    .interface__grid{
      max-height: 80vh;

      .interface__aside, .interface__main, .pannel{
        height: 80vh;
      }

      .interface__main{

        .hunt{
          max-height: 80vh;
          height: 79vh;
        }
      }


    }
  }

    .interface__grid{
      grid-template-columns: 1fr 4fr 1.4fr;
      column-gap: 16px;
      color: #f6f6f6;


      .interface__main {
        // background-color: #262c3a;
        
      }

      .interface__main::-webkit-scrollbar {
        display:none;
        }

      .interface__aside{
        overflow-x: hidden;
        
      }

      .interface__aside::-webkit-scrollbar {
        display:none;
        }

      .stats{
        overflow: hidden;

      }
    }

  }


</style>