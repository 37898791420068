<script>
export default {
  name: "MsgCard",

  props: {
    title: {
      type: String,
    },

    description: {
      type: String,
    }
  }
}
</script>

<template>
  <div class="card error" v-if="title === 'Jeton de sécurité invalide'">
    <h4 class="card__title">{{title}}</h4>
    <p class="card__description">{{description}}</p>
  </div>
</template>


<style lang="scss" scoped>

  .card {
      text-align: left;
      padding: 1em;
      border-radius: 8px;
      max-width: 300px;

      &.error{
        background-color: lightcoral;
      }

      .card__title {
        margin: 0em;
      }
      .card__description{
        margin-bottom: 0;
      }
    }

</style>