<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
import Multiselect from '@vueform/multiselect'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  name: 'generateSlotModal',
  components:{
    Multiselect,
    VueSlider,
  },
  data() {
    return {
      onGenerated: [],
      generator: {
        number: null,
        providers: [],
        volatility: 5,
        allProviders: true,
      },
      // Slider component options
      options: {
        dotSize: 14,
        width: '100%',
        height: 5,
        contained: false,
        direction: 'ltr',
        data: null,
        dataLabel: 'label',
        dataValue: 'value',
        min: 1,
        max: 5,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: 'active',
        tooltipPlacement: 'top',
        tooltipFormatter: void 0,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        dotAttrs: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
    }
  },
  computed:{
    ...mapState(['generatorModal', 'providers', 'providersSelectList', 'machinesList', 'slotList', 'huntList', 'currentBoard', 'user']),

    validateFields(){
      if(this.generator.number != null && this.generator.providers.length != 0) {
        if(this.buildList.length > 0 && this.buildList.length > this.generator.number) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    buildList(){
      const filteredList = []
      this.machinesList.forEach((machine) => {
        if(this.generator.providers.includes(((machine.provider_id)))){
          if(machine.volatility <= this.generator.volatility){
              filteredList.push(machine)
          }
        }
      })
      return filteredList
    },
  },

  methods:{
    ...mapActions(['toggleGeneratorModal', 'newSlotHuntList', 'fetchHuntList', 'addError']),

    resetFields(){
      this.generator.number = null;
      this.generator.providers = [];
      this.generator.volatility = 5;
      this.onGenerated = [];
    },

    async generateSlots() {
      for(let i = 0; i < this.generator.number; i++){
        let randomIndex = null;
        // Check if the machine is already in huntList
        while (randomIndex === null || this.slotList.includes(randomIndex.id)){
          randomIndex = this.buildList[Math.floor(Math.random() * this.buildList.length)];
        }
        this.onGenerated.push(randomIndex.id);
      }
      try{
        await axios.post(`${process.env.VUE_APP_API_URL}/generate`, {
          google_id: this.user.google_id,
          token: this.user.token,
          board_id: this.currentBoard,
          onGenerated: this.onGenerated,
        }).then(() => {
          this.fetchHuntList()
        })
      } catch (e) {
         if (e.message === "Request failed with status code 300") {
          this.addError({
            title: "Jeton de sécurité invalide",
            description:
              "Pour des raisons de sécurité, veuillez vous reconnecter.",
          });
         }
      }
    }
  }
}
</script>

<template>
  <div class="modal">
    <div class="modal__content">
      <h2 class="modal__title">Générateur de slots</h2>
      <p class="modal__desc">Choisis tes filtres et génère autant de slots que tu veux</p>
    </div>
    <div class="modal__form">
      <div class="form-row">
        <input v-model="generator.number" class="form-row__input" min="0" max="150" type="number" placeholder="Nombre de slots à générer"/>
      </div>
      <div class="form-row">
        <Multiselect
          mode="tags"
          v-model="generator.providers"
          placeholder="Choisissez vos providers préférés"
          :options="providersSelectList"
          :searchable="true"
          :closeOnSelect="false"
          class="custom__select"
        />
      </div>
      <div class="form-row">
        <!-- <input v-model="generator.volatility" class="form-row__input" type="number" placeholder="Volatilité max des slots"/> -->
        <label for="volatility">Volatilité max des slots</label>
      </div>
      <div class="form-row">
        <vue-slider v-model="generator.volatility" v-bind="options" />
      </div>
      <div class="form-row">
        <span>Générer aléatoirement {{generator.number || 'des'}} slots parmis {{buildList.length}} disponibles.</span>
      </div>
      <div class="form-row">
        <button @click="generateSlots(), toggleGeneratorModal(), resetFields()" class="modal__button" :disabled="!validateFields" :class="{'button__disabled' : !validateFields}">
          <span>Générer {{generator.number}} slots</span>
        </button>
      </div>
    </div>
    <div class="modal__cross" @click="toggleGeneratorModal(), resetFields()"><font-awesome-icon icon="times" /></div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@vueform/multiselect/themes/default';

  @media screen and (max-width: 592px){
    div.modal{
      width: calc(100% - 2em);
      margin: auto;

      .modal__title, .modal__desc{
        text-align: center;
      }
    }
  }

  .modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 199;
    background-color: #1C1E2A;
    padding: 2em;
    border-radius: 10px;
    -webkit-box-shadow: 9px 10px 45px 4px rgba(0,0,0,0.32);
    -moz-box-shadow: 9px 10px 45px 4px rgba(0,0,0,0.32);
    box-shadow: 9px 10px 45px 4px rgba(0,0,0,0.32);
    border: solid 1px #FE4B8B;

    .modal__desc{
      text-align: left;
      margin-top: 0;
      margin-bottom: 2em;
    }

    .modal__title{
      font-size: 2em;
      margin-bottom: 0.5em;
      text-align: left;
    }

    .modal__button{
      margin-top: 1em;
      width: 100%;
      padding: 0.9em 0;
      color: #f2f2f2;
      background-color: #FE4B8B;
      border: none;
      border-radius: 6px;
      font-size: 1.1em;
      font-weight: bold;
      cursor: pointer;
    }

    .button__disabled {
      cursor: default;
      background-color: rgb(119, 119, 119);
    }

    .form-row {
      display: flex;
      margin: 16px 0px;
      gap:16px;
      flex-wrap: wrap;
    }

    .form-row__input {
      padding: 11px;
      border-radius: 4px;
      background: #fff;
      font-weight: 500;
      font-size: 16px;
      flex: 1;
      border: 1px solid #2c2e3f;
      min-width: 100px;
      font-weight: 100;
      color: #252C3A;
    }
    
  
  
    .modal__cross{
      position: absolute;
      top: 0.7em;
      right: 1em;
      font-size: 2em;
      color: #252C3A;
      cursor: pointer;
      transition: all ease .2s;
    }

    .modal__cross:hover{
      color: #fff;
    }

  }

</style>