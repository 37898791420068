import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import cors from "cors";

// Google Authentication
import GAuth from "vue3-google-oauth2";
const gauthOption = {
  clientId:
    "521686346785-fsi8v9l4pco2tibl9id201d3conk8ivc.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "consent",
  fetch_basic_profile: true,
};

const corsOption = {
  credentials: true,
};

// Fontawesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { faCompress } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";

library.add(faTimes);
library.add(faExchangeAlt);
library.add(faAngleRight);
library.add(faAngleLeft);
library.add(faSignOutAlt);
library.add(faPlus);
library.add(faClipboard);
library.add(faExpand);
library.add(faCompress);
library.add(faCog);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(cors, corsOption)
  .use(store)
  .use(GAuth, gauthOption)
  .mount("#app");
