<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'UserDisplay',
  computed: {
    ...mapState(['isLogin', 'user'])
  },
  methods: {
    ...mapActions(['clearUser']),


    // Disconnect user
    async handleClickSignOut(){
      try {
        await this.$gAuth.signOut()
        this.clearUser()
      } catch (error) {
        // On fail do something
      }
    }
  }
}

</script>

<template>
  <div class="user" v-if="isLogin">
    <span class="user__name">{{ user.username }}</span>
    <div class="user__picture">
      <img :src="user.picture_url">
    </div>
    <button @click="handleClickSignOut()" title="Se déconnecter"><font-awesome-icon icon="sign-out-alt" /></button>
  </div>  
</template>

<style lang="scss" scoped>

  @media screen and (max-width: 592px){
    .user{

      .user__name{
        display: none;
      }
    }
  }

  .user{
    display: flex;
    justify-content: center;
    align-items: center;

    .user__picture{
      width: 40px;
      margin: 0 1em;

      img{
        width: 100%;
        border-radius: 50%;
      }
    }

    button{
      border: none;
      font-size: 1.3em;
      background: transparent;
      color: #f6f6f6;
      transition: all ease-in-out .2s;
      cursor: pointer;
    }

    button:hover{
      transform: scale(1.05);
    }
  }

</style>