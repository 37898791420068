<script>
import { mapState, mapActions } from 'vuex'
import CardBoard from '@/components/CardBoard.vue'
import confirmDeleteModal from './modals/confirmDeleteModal.vue'
import GuestCardBoard from '@/components/GuestCardBoard.vue'


export default {
  name: 'AsideBoards',
  components:{
    CardBoard,
    confirmDeleteModal,
    GuestCardBoard,
  },
  computed:{
    ...mapState(['isLogin', 'boards', 'currentBoard', 'onDeleteBoard', 'guestPresent', 'guestHuntList', 'guestBoard', 'guestBoardId'])
  },
  methods:{
    ...mapActions(['fetchBoards', 'toggleBoardModal', 'selectBoard', 'fetchHuntList', 'selectDevise', 'selectStartBet', 'toggleDeleteBoardModal', 'resetOnAction', 'toggleBoardLoader', 'affectGuestHuntList', 'selectGuestBoard', 'resetGuestBoard', 'resetHuntList', 'trueGuestFetchLoop', 'falseGuestFetchLoop', 'guestFetchHuntLoop', 'huntListHerit', 'resetHuntlistAfterGuest'])
  },
  mounted(){
    this.fetchBoards()
  }
}
</script>

<template>
  <div class="pannel" v-if="isLogin">
    <button @click="toggleBoardModal()" class="pannel_new_board">
        <font-awesome-icon icon="plus" />
        <span>Ajoutez un tableau</span>
      </button>
    <ul class="pannel__list">
      <GuestCardBoard v-if="guestPresent && guestBoard.name != undefined"
        class="guestBoard"
        :id="guestBoard.id"
        :name="guestBoard.name"
        :devise="guestBoard.devise"
        :key="guestBoard.id"
        :start_bet="parseInt(guestBoard.start_bet)"
         @click="resetHuntList(), huntListHerit(), selectGuestBoard(guestBoard.id), selectBoard(guestBoard.id), selectDevise(guestBoard.devise), selectStartBet(guestBoard.start_bet) ,resetOnAction(), trueGuestFetchLoop(), guestFetchHuntLoop()"
        />
      <CardBoard
        v-for="item in boards"
        :id="item.id"
        :name="item.name"
        :devise="item.devise"
        :start_bet="parseInt(item.start_bet)"
        :createdAt="item.createdAt"
        :key="item.id"
        @click="falseGuestFetchLoop(), guestFetchHuntLoop(), resetHuntlistAfterGuest(), selectBoard(item.id), selectDevise(item.devise), selectStartBet(item.start_bet), fetchHuntList(), resetOnAction(), toggleBoardLoader()"
      />
    </ul>
    <div class="pannel__control">
      <confirmDeleteModal v-if="currentBoard != guestBoardId"/>
      <button @click="toggleDeleteBoardModal()" v-if="currentBoard != null && currentBoard != guestBoardId" :class="{'inactive' : onDeleteBoard}">Supprimer le tableau</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  .pannel::-webkit-scrollbar {
        display:none;
  }

  .guestBoard{
    border-left: 1px solid lightgray;
    border-radius: 0px;
  }

  .pannel {
    position: relative;
    padding: 1em 0;
    border-radius: 8px;
    background-color: #1c1e2a;
    border: 1px solid #2c2e3f;
    height: 100%;
    height: 700px;
    overflow-y: scroll;
    overflow-x: hidden;

    .pannel_new_board{
        position: sticky;
        top: 0;
        width: calc(100% - 2em);
        padding: 1em;
        color: #fff;
        background-color: #fe4b8c;
        font-weight: bold;
        border: 1px solid #ff72a6;
        border-radius: 6px;
        margin-bottom: 1em;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        z-index: 49;

        span{
          margin-left: 1em;
        }
      }

    .pannel__control{
      position: sticky;
      bottom: 0;
      width: 100%;
      padding: 0 1em;
      z-index: 49;

      button{
        width: 100%;
        padding: 1em;
        color: #222;
        background-color: #fff;
        border: 1px solid #D1D5DB;
        font-weight: bold;
        border-radius: 6px;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        z-index: 49;
        transition: all ease 0.2s;
      }

      .inactive{
        transform: translateY(150%);
      }
    }

    .pannel__list{
      list-style: none;
      width: 100%;
      padding: 0.4em 1.4em;
      min-height: 100%;

    }
  }

</style>