import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    //loader
    // btnAddLoad: false,
    // btnDelLoad: false,
    boardLoader: false,

    // Modals
    boardModal: false,
    generatorModal: false,
    editBoardModal: false,

    // Login / Logout
    token: localStorage.getItem("user-token") || "",
    user: null,
    isInit: false,
    isLogin: false,

    // Boards
    boards: [],
    currentBoard: null,
    currentDevise: null,
    currentStartBet: null,
    onDeleteBoard: false,

    currentBoardInfos: null,

    // HuntsList
    guestPresent: false,
    guestBoardId: null,
    guestDevise: null,
    guestBoard: {},
    guestKey: 0,
    guestHuntList: [],
    guestFetchLoop: false,
    huntList: [],

    statKey: 0,

    guestSlotList: [],
    // All slot ID in current huntList
    slotList: [],
    // All slot from DB
    machinesList: [],
    // All available slot from DB
    selectList: [],

    huntListKey: 0,

    // Providers
    providers: [],
    providersSelectList: [],

    // Slots gestion
    onAction: [],

    returnToSelectList: [],

    // Globals errors
    errors: [],
  },
  mutations: {
    PUSH_ERROR(state, error) {
      state.errors.push(error);
      setTimeout(() => {
        state.errors.splice(0, 1);
      }, 10000);
    },

    // U S E R S

    // Save user informations after Login
    SET_USER_INFOS(state, user) {
      state.user = user;
      state.isLogin = true;

      // save user in local storage
      const parsed = JSON.stringify(state.user);
      // console.log(JSON.stringify(state.user));
      localStorage.setItem("user", parsed);
      // console.log(parsed);
    },

    // Clear user on disconnection & clear local storage user
    CLEAR_USER(state) {
      state.user = null;
      state.isLogin = false;
      state.currentBoard = null;
      state.huntList = [];
      localStorage.removeItem("user");
    },

    // B O A R D S

    TOGGLE_BOARD_MODAL(state) {
      state.boardModal = !state.boardModal;
    },

    TOGGLE_EDIT_BOARD_MODAL(state) {
      state.editBoardModal = !state.editBoardModal;
    },

    TOGGLE_DELETE_BOARD_MODAL(state) {
      state.onDeleteBoard = !state.onDeleteBoard;
    },

    TOGGLE_GENERATOR_MODAL(state) {
      state.generatorModal = !state.generatorModal;
    },

    SELECT_BOARD(state, id) {
      state.currentBoard = id;
      state.returnToSelectList = state.slotList;
      state.slotList = [];
    },

    SAVE_BOARD_INFOS(state, id) {
      const target = (entry) => entry.id === id;
      const entry = state.boards.findIndex(target);
      state.currentBoardInfos = {
        name: state.boards[entry].name,
        devise: state.boards[entry].devise,
        start_bet: state.boards[entry].start_bet,
      };
      state.statKey += 1;
    },

    SAVE_GUEST_BOARD_INFOS(state, board) {
      state.currentBoardInfos = {
        name: board.name,
        devise: board.devise,
        start_bet: board.start_bet,
      };
      state.statKey += 1;
    },

    RESET_BOARD(state) {
      state.currentBoard = null;
    },

    SELECT_DEVISE(state, devise) {
      state.currentDevise = devise;
    },

    SELECT_START_BET(state, bet) {
      state.currentStartBet = bet;
    },

    // Update the boards var with last fetched response
    UPDATE_BOARDS_LIST(state, updatedList) {
      state.boards = updatedList;
    },

    // H U N T L I S T S
    UPDATE_GUEST_SLOT_LIST(state, list) {
      state.guestPresent = true;
      state.guestHuntList = list;
      state.huntList = state.guestHuntList;
    },

    HUNTLIST_HERIT(state) {
      state.huntList = state.guestHuntList;
    },

    // Update the huntList var with last fetched response
    UPDATE_HUNTLIST(state, updatedList) {
      state.huntList = updatedList;
      state.huntListKey += 1;
      state.boardLoader = false;
    },

    UPDATE_LOCAL_HUNTLIST_ENTRY(state, values) {
      // console.log("values : ", values);
      const target = (entry) => entry.id === values.id;
      const entry = state.huntList.findIndex(target);
      state.huntList[entry].slot_id = values.slot_id;
      state.huntList[entry].bet = values.bet;
      state.huntList[entry].earn = values.earn;
    },

    RESET_HUNTLIST(state) {
      state.huntList = [];
    },

    TOGGLE_BOARD_LOADER(state) {
      state.boardLoader = !state.boardLoader;
    },

    // All slots from DB
    PUSH_MACHINES_LIST(state, data) {
      state.machinesList = data;
    },

    REMOVE_FROM_SLOT_LIST(state) {
      state.toRemoveFromSlotList.forEach((slot) => {
        const target = (entry) => entry.value === slot.value;
        const entry = state.selectList.findIndex(target);
        state.selectList.splice(entry, 1);
      });
    },

    // PUSH_TO_SELECT_LIST(state) {
    //   state.slotList.forEach((slot) => {
    //     let machine = state.machinesList.find((machine) => machine.id === slot);
    //     if (!state.slotList.includes(machine.id)) {
    //       let findProvider = state.providers.find(
    //         (provider) => provider.id === machine.provider_id
    //       );
    //       const options = {
    //         value: machine.id,
    //         label: `${machine.name} (${findProvider.name})`,
    //       };
    //       state.selectList.push(options);
    //     }
    //   });
    // },

    // All slot ID from current Huntlist
    ACTUALIZE_SLOT_LISTS(state) {
      state.slotList = [];

      state.huntList.forEach((hunt) => {
        if (hunt.slot_id != null && !state.slotList.includes(hunt.slot_id)) {
          state.slotList.push(hunt.slot_id);
        }
      });

      const availableMachines = state.machinesList.filter(
        (machine) => !state.slotList.includes(machine.id)
      );

      // console.log(availableMachines);

      const buildOptions = [];

      availableMachines.forEach((machine) => {
        let findProvider = state.providers.find(
          (provider) => provider.id === machine.provider_id
        );
        buildOptions.push({
          value: machine.id,
          label: `${machine.name} (${findProvider.name})`,
        });
      });

      const toPush = buildOptions.filter(
        (options) => !state.selectList.includes(options)
      );

      state.selectList = toPush;
    },

    ACTUALIZE_SLOT_LIST(state) {
      if (state.returnToSelectList.length > 0) {
        state.machinesList.forEach((machine) => {
          if (state.returnToSelectList.includes(machine.id)) {
            let findProvider = state.providers.find(
              (provider) => provider.id === machine.provider_id
            );
            const options = {
              value: machine.id,
              label: `${machine.name} (${findProvider.name})`,
            };
            if (!state.selectList.includes(options)) {
              state.selectList.push(options);
            }
          }
        });
        state.returnToSelectList = [];
      }

      state.huntList.forEach((hunt) => {
        if (hunt.slot_id != null && !state.slotList.includes(hunt.slot_id)) {
          state.slotList.push(hunt.slot_id);
        }
      });

      // console.log("actualize select list ...");
      if (state.selectList.length === 0)
        state.machinesList.forEach((machine) => {
          if (!state.slotList.includes(machine.id)) {
            // console.log(!state.slotList.includes(machine.id));
            let findProvider = state.providers.find(
              (provider) => provider.id === machine.provider_id
            );
            const options = {
              value: machine.id,
              label: `${machine.name} (${findProvider.name})`,
            };
            if (state.selectList.indexOf(options) === -1) {
              state.selectList.push(options);
            }
          }
        });
      else {
        state.selectList.forEach((slot) => {
          if (state.slotList.includes(slot.value)) {
            // console.log(
            //   "useless entry find in the select list -> ",
            //   slot.value
            // );
            const target = (entry) => entry.value === slot.value;
            const entry = state.selectList.findIndex(target);
            state.selectList.splice(entry, 1);
          }
        });
      }
    },

    PUSH_PROVIDERS_LIST(state, data) {
      state.providers = data.data;
    },

    ACTUALIZE_PROVIDERS_SELECT_LIST(state) {
      state.providers.forEach((provider) => {
        state.providersSelectList.push({
          value: provider.id,
          label: provider.name,
        });
      });
    },

    PUSH_SLOT_TO_QUEUE(state, id) {
      state.onAction.push(id);
    },

    REMOVE_SLOT_FROM_QUEUE(state, id) {
      const index = state.onAction.findIndex(id);
      state.onAction.splice(index, 1);
    },

    RESET_ON_ACTION(state) {
      state.onAction = [];
    },

    SAVE_GUEST_BOARD(state, object) {
      state.guestBoard = object;
    },

    SELECT_GUEST_BOARD(state, id) {
      state.guestBoardId = id;
    },

    RESET_GUEST_BOARD(state) {
      state.guestBoardId = null;
    },

    TRUE_GUEST_FETCH_LOOP(state) {
      state.guestFetchLoop = true;
    },

    FALSE_GUEST_FETCH_LOOP(state) {
      state.guestFetchLoop = false;
    },

    INCREMENT_GUEST_KEY(state) {
      state.guestKey += 1;
    },

    INCREMENT_STAT_KEY(state) {
      state.statKey += 1;
    },

    UPDATE_GUEST_DEVISE(state, devise) {
      state.guestDevise = devise;
    },

    RESET_HUNTLIST_AFTER_GUEST(state) {
      if (state.currentBoard === state.guestBoardId) {
        state.huntList = [];
      }
    },
  },
  actions: {
    // U S E R S

    // Call the save of user informations after Login
    async setUser({ commit }, user) {
      commit("SET_USER_INFOS", user);
      try {
        await axios
          .get(`${process.env.VUE_APP_API_URL}/check/${user.google_id}`)
          .then((response) => {
            if (response.status === 201) {
              axios.post(`${process.env.VUE_APP_API_URL}/user`, {
                google_id: user.google_id,
                email: user.email,
                token: user.token,
              });
            }
          });
        // actualize token
        await axios
          .post(`${process.env.VUE_APP_API_URL}/token`, {
            google_id: user.google_id,
            token: user.token,
          })
          .then((response) => {
            if (response.status === 300) {
              commit("PUSH_ERROR", {
                title: "Jeton de sécurité invalide",
                description:
                  "Pour des raisons de sécurité, veuillez vous reconnecter.",
              });
            }
          });
      } catch (e) {
        if (e.message === "Request failed with status code 300")
          commit("PUSH_ERROR", {
            title: "Jeton de sécurité invalide",
            description:
              "Pour des raisons de sécurité, veuillez vous reconnecter.",
          });
        commit("CLEAR_USER");
      }
    },

    addError({ commit }, error) {
      commit("PUSH_ERROR", error);
    },

    // Clear user on disconnect
    clearUser({ commit }) {
      commit("CLEAR_USER");
    },

    // B O A R D S

    // toggle boardModal
    toggleBoardModal({ commit }) {
      commit("TOGGLE_BOARD_MODAL");
    },

    toggleEditBoardModal({ commit }) {
      commit("TOGGLE_EDIT_BOARD_MODAL");
    },

    toggleDeleteBoardModal({ commit }) {
      commit("TOGGLE_DELETE_BOARD_MODAL");
    },

    toggleGeneratorModal({ commit }) {
      commit("TOGGLE_GENERATOR_MODAL");
    },

    toggleBoardLoader({ commit }) {
      commit("TOGGLE_BOARD_LOADER");
    },

    selectBoard({ commit, state }, id) {
      commit("SELECT_BOARD", id);
      if (state.guestBoardId != state.currentBoard) {
        commit("SAVE_BOARD_INFOS", id);
      }
    },

    selectDevise({ commit }, devise) {
      commit("SELECT_DEVISE", devise);
    },

    selectStartBet({ commit }, bet) {
      commit("SELECT_START_BET", bet);
    },

    huntListHerit({ commit }) {
      commit("HUNTLIST_HERIT");
    },

    incrementStatKey({ commit }) {
      commit("INCREMENT_STAT_KEY");
    },

    // Ask to the API the last list of board of the user
    async fetchBoards({ commit, dispatch, state }) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/boards/${state.user.google_id}`
        );
        commit("UPDATE_BOARDS_LIST", response.data);
        dispatch("incrementStatKey");
      } catch (e) {
        state.errors.push(e);
      }
    },

    // Post new board to the API
    async postBoard({ state, dispatch }, newBoard) {
      try {
        await axios
          .post(`${process.env.VUE_APP_API_URL}/boards`, {
            google_id: state.user.google_id,
            token: state.user.token,
            name: newBoard.name,
            devise: newBoard.devise,
            start_bet: newBoard.start_bet,
          })
          .then(() => dispatch("fetchBoards"));
      } catch (e) {
        state.errors.push(e);
      }
    },

    async editBoard({ state, commit, dispatch }, editedBoard) {
      try {
        await axios
          .post(`${process.env.VUE_APP_API_URL}/board/edit`, {
            google_id: state.user.google_id,
            token: state.user.token,
            name: editedBoard.name,
            devise: editedBoard.devise,
            start_bet: editedBoard.start_bet,
            id: state.currentBoard,
          })
          .then(() => {
            dispatch("fetchBoards").then(() => {
              commit("SAVE_BOARD_INFOS", state.currentBoard);
            });
          });
      } catch (e) {
        state.errors.push(e);
      }
    },

    // Delete board & clear all entry from DB
    async deleteBoard({ state, dispatch, commit }) {
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/deleteboards/${state.currentBoard}`,
          {
            google_id: state.user.google_id,
            token: state.user.token,
          }
        );
        commit("RESET_BOARD");
        dispatch("fetchBoards");
      } catch (e) {
        if (e.message === "Request failed with status code 300") {
          commit("PUSH_ERROR", {
            title: "Jeton de sécurité invalide",
            description:
              "Pour des raisons de sécurité, veuillez vous reconnecter.",
          });
          commit("CLEAR_USER");
        } else {
          state.errors.push(e);
        }
      }
    },

    // H U N T L I S T S

    updateGuestSlotList({ commit }, list) {
      commit("UPDATE_GUEST_SLOT_LIST", list);
    },

    saveGuestBoard({ commit }, object) {
      commit("SAVE_GUEST_BOARD", object);
      commit("UPDATE_GUEST_DEVISE", object.devise);
    },

    selectGuestBoard({ commit, state }, id) {
      commit("SELECT_GUEST_BOARD", id);
      commit("SAVE_GUEST_BOARD_INFOS", state.guestBoard);
    },

    resetGuestBoard({ commit }) {
      commit("RESET_GUEST_BOARD");
    },

    resetHuntList({ commit }) {
      commit("RESET_HUNTLIST");
    },

    resetHuntlistAfterGuest({ commit }) {
      commit("RESET_HUNTLIST_AFTER_GUEST");
    },

    trueGuestFetchLoop({ commit }) {
      commit("TRUE_GUEST_FETCH_LOOP");
    },

    falseGuestFetchLoop({ commit }) {
      commit("FALSE_GUEST_FETCH_LOOP");
    },

    async guestFetchHuntLoop({ commit, state }) {
      const boardId = state.guestBoardId;
      if (state.guestFetchLoop) {
        const interval = setInterval(() => {
          axios
            .get(`${process.env.VUE_APP_API_URL}/huntlist/${boardId}`)
            .then((response) => {
              if (response.status == 200) {
                if (state.guestFetchLoop) {
                  commit("UPDATE_GUEST_SLOT_LIST", response.data);
                }
              }
              if (!state.guestFetchLoop) {
                clearInterval(interval);
              }
              commit("INCREMENT_GUEST_KEY");
            });
        }, 5000);
      }
    },

    // Fetch all hunts from currentBoard selected
    async fetchHuntList({ commit, state }) {
      try {
        await axios
          .get(`${process.env.VUE_APP_API_URL}/huntlist/${state.currentBoard}`)
          .then((response) => {
            if (response.status === 200 && response.data) {
              commit("UPDATE_HUNTLIST", response.data);
              if (state.slotList.length === 0) {
                commit("ACTUALIZE_SLOT_LIST");
              }
              // commit("ACTUALIZE_MACHINES_SELECT_LIST");
            }
          });
      } catch (e) {
        if (e.message === "Request failed with status code 300") {
          commit("PUSH_ERROR", {
            title: "Jeton de sécurité invalide",
            description:
              "Pour des raisons de sécurité, veuillez vous reconnecter.",
          });
          commit("CLEAR_USER");
        } else {
          state.errors.push(e);
        }
      }
    },

    // Push to the API the new slot just created
    async newSlotHuntList({ state, commit, dispatch }, slot_id = null) {
      try {
        await axios
          .post(`${process.env.VUE_APP_API_URL}/huntlist`, {
            board_id: state.currentBoard,
            slot_id: slot_id,
            bet: null,
            earn: null,
            google_id: state.user.google_id,
            token: state.user.token,
          })
          .then(() => {
            dispatch("fetchHuntList");
          });
      } catch (e) {
        if (e.message === "Request failed with status code 300") {
          commit("PUSH_ERROR", {
            title: "Jeton de sécurité invalide",
            description:
              "Pour des raisons de sécurité, veuillez vous reconnecter.",
          });
          commit("CLEAR_USER");
        } else {
          state.errors.push(e);
        }
      }
    },

    updateLocalHuntlistEntry({ commit }, values) {
      commit("UPDATE_LOCAL_HUNTLIST_ENTRY", values);
    },

    // Fetch all slots from DB
    async fetchMachinesList({ commit, state }) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/slots`
        );
        commit("PUSH_MACHINES_LIST", response.data);
        commit("ACTUALIZE_SLOT_LIST");
      } catch (e) {
        if (e.message === "Request failed with status code 300") {
          commit("PUSH_ERROR", {
            title: "Jeton de sécurité invalide",
            description:
              "Pour des raisons de sécurité, veuillez vous reconnecter.",
          });
          commit("CLEAR_USER");
        } else {
          state.errors.push(e);
        }
      }
    },

    actualizeSlotList({ commit }) {
      commit("ACTUALIZE_SLOT_LIST");
    },

    actualizeSelectList({ commit }) {
      commit("ACTUALIZE_MACHINES_SELECT_LIST");
    },

    // Fetch all providers from DB
    async fetchProviders({ commit, state }) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/providers`
        );
        commit("PUSH_PROVIDERS_LIST", response);
        commit("ACTUALIZE_PROVIDERS_SELECT_LIST");
      } catch (e) {
        state.errors.push(e);
      }
    },

    // actualizeCard({ commit }, index, body, card_id) {
    //   commit("ACTUALIZE_CARD", index, body, card_id);
    // },

    // Push slot to onAction array
    pushSlotToQueue({ commit }, id) {
      commit("PUSH_SLOT_TO_QUEUE", id);
    },

    // Remove slot form onAction array
    removeSlotFromQueue({ commit }, id) {
      commit("REMOVE_SLOT_FROM_QUEUE", id);
    },

    // Delete all slot from onAction Array
    // async multiDeleteSlots({ commit, dispatch, state }) {

    //   try {
    //     state.onAction.forEach(async (id) => {
    //       await axios.post(`${process.env.VUE_APP_API_URL}/deletehuntlist/${id}`, {
    //         google_id: state.user.google_id,
    //         token: state.user.token,
    //       });
    //       const target = (element) => element.id === id;
    //       const index = state.huntList.findIndex(target);
    //       state.huntList.splice(index, 1);
    //     });
    //   } catch (e) {
    //     this.errors.push(e);
    //   }
    //   if (state.huntList.length != initialLength - state.onAction.length) {
    //     const keepRefresh = setInterval(() => {
    //       dispatch("fetchHuntList");
    //       // console.log("1 tour");
    //       if (state.huntList.length === initialLength - state.onAction.length) {
    //         clearInterval(keepRefresh);
    //         commit("RESET_ON_ACTION");
    //         // console.log("cleared");
    //       }
    //     }, 500);
    //   }
    // },

    async multiDeleteSlots({ commit, dispatch, state }) {
      try {
        await axios
          .post(`${process.env.VUE_APP_API_URL}/deletehuntlist`, {
            board_id: state.currentBoard,
            google_id: state.user.google_id,
            token: state.user.token,
            onDelete: state.onAction,
          })
          .then(() => {
            dispatch("fetchHuntList").then(() => {
              commit("ACTUALIZE_SLOT_LIST");
              commit("RESET_ON_ACTION");
            });
          });
      } catch (e) {
        if (e.message === "Request failed with status code 300") {
          commit("PUSH_ERROR", {
            title: "Jeton de sécurité invalide",
            description:
              "Pour des raisons de sécurité, veuillez vous reconnecter.",
          });
          commit("CLEAR_USER");
        } else {
          state.errors.push(e);
        }
      }
    },

    resetOnAction({ commit }) {
      commit("RESET_ON_ACTION");
    },
  },
  modules: {},
});
