<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "GuestCardBoard",

  props: {
    id:{
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    devise: {
      type: String,
      required: true,
    },
    start_bet: {
      type: Number,
      required: true,
    },
  },
  computed:{
    ...mapState(['boards', 'currentBoard', 'boardLoader', 'guestPresent', 'guestBoard', 'guestBoardId']),

  },
  methods:{
    ...mapActions(['fetchBoards', 'resetBoard']),
    
  },
}
</script>

<template>
  <li class="card" v-if="guestPresent && guestBoard.name != undefined" :class="{'card__active' : guestBoardId === currentBoard}">
    <h3 class="card__title">{{ name }}</h3>
    <div class="card__info">
      <span>Tableau partagé</span>
      <span>{{ start_bet }}{{ devise }}</span>
    </div>
    <div class="board__loader" v-show="boardLoader">
      <img src="../assets/loader.gif" alt="">
    </div>
  </li>
</template>

<style lang="scss" scoped>

  @media screen and (max-width: 1300px){
    
    .card__active{
      transform: translateX(22px) scale(1) !important;
      
    }
  }


  .card {
    padding: 1em;
    cursor: pointer;
    margin-bottom: 1em;
    position: relative;
    border-radius: 6px 0 0 6px;
    transition: all ease .2s;

    .card__title {
      margin-top: 0;
      text-align: left;
      color: #fff;
      font-weight: bold;
    }

    .card__info{
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      color: #fff;
    }

    .board__loader{
      position: absolute;
      top: 1em;
      right: 1em;
      visibility: hidden;

      img{
        width: 25px;
      }
    }
    
  }

  .card__active{
    background-color: #262c3a; 
    transform: translateX(22px) scale(1.08);
    position: sticky;
    top : 5em;
    bottom : 4em;
    z-index: 30;
    
    .board__loader{
        visibility: visible;
    }
    
    .card__title{
      color: #FE4B8B;
    }
  }

</style>