<script>
import { mapState, mapActions } from 'vuex'

export default {
  name:'confirmDeleteModal',

  computed:{
    ...mapState(['onDeleteBoard'])
  },

  methods:{
    ...mapActions(['toggleDeleteBoardModal', 'deleteBoard'])
  }
}
</script>

<template>
  <transition name='modal-slide'>
    <div class="modal" v-show='onDeleteBoard'>
      <h3 class="modal__title">Êtes-vous sûr de vouloir supprimer ce tableau ?</h3>
      <div class="modal__control">
        <button @click="deleteBoard(), toggleDeleteBoardModal()" class="delete-button">Supprimer</button>
        <button @click="toggleDeleteBoardModal()">Annuler</button>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>

  .modal{
    opacity: 1;
    padding: 1em;
    position: absolute;
    bottom: 1em;
    width: calc(100% - 2em);
    margin: auto;
    left: 0;
    right:0;
    background-color:#252C3A;
    border-radius: 8px;
    z-index: 50;
    transition: all ease 0.2s;

    .modal__title{
      margin: 0 0 1em 0;
    }

    .modal__control{
      display: flex;
      flex-direction: column;

      button{
        padding: 0.7em;
        font-weight: bold;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }

      .delete-button{
        color: #fff;
        background-color: #f74242;
        border: 1px solid #ff7070;
      }
    }

    .modal__control:last-child button{
      margin-top: 0.7em;
    }

  }

  .modal-slide-enter-active{
    opacity: 0;
    transition: all ease .2s;
    transform: translateY(30%);
  }

  .modal-slide-enter{
    opacity: 1;
  }

  .modal-slide-leave-to{
    opacity: 0;
    transform: translateY(-30%)
  }


</style>